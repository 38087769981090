import fetchJson from "@/lib/fetchJson";
import useSWR from "swr";

type DuplicateType = Post & { campaign: Campaign };
export const useDuplicates = () =>
  useSWR<DuplicateType[][]>("/post/duplicates", fetchJson);

export const deletePost = async (id: number) => {
  await fetchJson(`/post/${id}`, {
    method: "DELETE",
  });
};

export const usePostHistory = (id: string) =>
  useSWR(`/post/${id}/history`, fetchJson);

export const useSpotifyPlaylists = () =>
  useSWR(`/post/spotify-playlist`, fetchJson);

export const getXPostByHashtag = async (hashtag: string) => {
  return await fetch(`${process.env.NEXT_PUBLIC_DOMAIN}/scraper/scraper/twitter/hashtag?hashtag=${hashtag}`, {
    headers: {
      "Content-Type": "application/json",
    }
  })
}
