"use client";
import React, { useRef } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";


import { twMerge } from "tailwind-merge";

interface Props {
  options: Option[];
  selected?: Option;
  setSelected: Function;
  title: string;
  removable?: boolean;
  size?: "sm" | "md" | "lg";
  removeColor?: string;
  width?: string;
}

interface Option {
  text: string;
  value: number | string;
}

const Select = ({
  options,
  selected,
  setSelected,
  title,
  size = "lg",
  width,
  removable = true,
  removeColor,
}: Props) => {
  // const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  // const handleClickOutside = (event: any) => {
  //   if (
  //     dropdownRef.current &&
  //     !(dropdownRef.current as any).contains(event.target)
  //   ) {
  //     // setOpen(false);
  //   }
  // };
  //
  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  //
  const toggleOption = (option: Option) => {
    setSelected(option);
    // setOpen(false);
  };

  return (
    <div className={twMerge("top-24 text-right flex items-center gap-2")}>
      <div className={width}>
        <Menu>
          <MenuButton className="inline-flex justify-between items-center gap-2 rounded-md bg-main-secondary py-2 px-4 text-main-text shadow-inner shadow-white/10 focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white w-full">
            <div>{selected?.text || title}</div>
            <ChevronDownIcon className="w-5" />
          </MenuButton>
          <Transition
            enter="transition ease-out duration-75"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <MenuItems
              anchor="bottom end"
              className={twMerge(
                `origin-top-right rounded-b-xl border border-white/5 bg-main-secondary p-1 text-sm/6  [--anchor-gap:var(--spacing-1)] focus:outline-none !z-50 "`,
                width,
              )}
            >
              {options.map((option) => (
                <MenuItem key={option.value}>
                  <div
                    className="flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 text-main-text bg-main-secondary font-poppins cursor-pointer customm"
                    onClick={() => toggleOption(option)}
                  >
                    {option.text}
                  </div>
                </MenuItem>
              ))}
            </MenuItems>
          </Transition>
        </Menu>
      </div>
      {selected && removable ? (
        <XMarkIcon
          className={`h-6 cursor-pointer ${removeColor}`}
          onClick={() => setSelected(null)}
        />
      ) : (
        ""
      )}
    </div>
  );

  // return (
  //   <div
  //     className="relative inline-block text-left text-main-text w-full "
  //     ref={dropdownRef}
  //   >
  //     <div className="flex items-center">
  //       <div
  //         className={`cursor-pointer ${size == "lg" ? "py-2 px-3" : "py-1 px-4"} rounded bg-main-secondary w-full flex items-center justify-between border border-black `}
  //         onClick={() => setOpen(!open)}
  //       >
  //         <div>
  //           {title} {selected?.text}
  //         </div>
  //         <ChevronDownIcon className={`inline h-5 `} />
  //       </div>
  //       <div className="mr-3"></div>
  //       {selected && removable ? (
  //         <XMarkIcon
  //           className={`h-6 cursor-pointer ${removeColor}`}
  //           onClick={() => setSelected(null)}
  //         />
  //       ) : (
  //         ""
  //       )}
  //     </div>
  //     {open && (
  //       <div className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-main-secondary ring-1 ring-black ring-opacity-5 overflow-hidden max-h-[500px] overflow-y-scroll z-50">
  //         <div className="py-1">
  //           {options.map((option) => (
  //             <label
  //               key={option.value}
  //               className="flex items-center px-4 py-2 text-sm text-main-text"
  //               onClick={() => toggleOption(option)}
  //             >
  //               <div className="ml-3">{option?.text}</div>
  //             </label>
  //           ))}
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
};
export default Select;
