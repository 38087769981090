import { Button } from "@radix-ui/themes";
import { IconUpload } from "@tabler/icons-react";
import NextImage from "next/image";
import React, { ChangeEventHandler, useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

const NewLogoPicker = ({ setImage }: { setImage: any }) => {
  function getCroppedImg(image: HTMLImageElement, crop: Crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * 0.3;
    canvas.height = crop.height * 0.3;
    const ctx = canvas.getContext("2d");

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * 0.3,
      crop.height * 0.3,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        let fileUrl = null;
        fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, "image/png");
    });
  }
  const [src, setSrc] = useState(null as any);
  const imageRef = useRef(null);
  const uploadRef = useRef<HTMLInputElement>(null);
  const onSelectFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc({ src: reader.result });
        var image = new Image();
        if (reader.result) image.src = reader.result as any;
        image.onload = function() {
          // access image size here
          setCrop({
            x: 0,
            y: 0,
            width: Math.min(
              Math.min(image.width, (400 / image.height) * image.width),
              528,
            ),
            height: Math.min(
              Math.min(image.height, 400),
              (526 / image.width) * image.height,
            ),
            unit: "px",
          });
        };
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [crop, setCrop] = useState<Crop>();
  return (
    <div>
      <div className="flex" >
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={onSelectFile}
          ref={uploadRef}
        />
        <Button
        variant="ghost"
        style={{margin: '0 auto'}}
          onClick={() => {
            uploadRef.current?.click();
          }}
        >
          <IconUpload className="icon-accent" width="18px"/>
          Upload custom logo
        </Button>
      </div>
      {src && (
        <ReactCrop
          crop={crop}
          onChange={(c) => {
            setCrop(c);
            console.log(c);
          }}
          onComplete={async (_) => {
            if (imageRef.current && crop)
              setImage(await getCroppedImg(imageRef.current, crop));
          }}
        >
          <div style={{ height: "400px", width: "400px", position: 'relative' }}>
          <NextImage 
            fill
            src={src.src}
            ref={imageRef}
            objectFit={'contain'}
            
            alt="Logo"
          />
          </div>
        </ReactCrop>
      )}
    </div>
  );
};

export default NewLogoPicker;
